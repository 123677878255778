import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import UserPool from "../UserPool";

const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [creation, setCreation] = useState(null);
  const [passreq, setPassreq] = useState(null);
  const [errorm, setErrorm] = useState("")

  let navigate = useNavigate();
  const routeChangetohome = () =>{
    let path = '/';
    navigate(path);
  }

  const onSubmit = (event) => {
    event.preventDefault();

    UserPool.signUp(email, password, [], null, (err, data) => {
      if (err) {
        console.error(err);
        setPassreq(true)
        setCreation(false)
        setErrorm(err)
      }
      else {
        setCreation(true)
        setPassreq(false)
        routeChangetohome();
      }
      console.log(data);
    });
  };

  return (
    <div>
      <form onSubmit={onSubmit}>
        <label class="signup-label" htmlFor="email">Email</label>
        <input
          class="signup-input"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        ></input>
        <label class="signup-label" htmlFor="password">Password</label>
        <input
          class="signup-input"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        ></input>
        <button class="signup-button" type="submit">Signup</button>
      </form>
      <div>
        {creation && <h1>Success!</h1>}
        {passreq && <h1>Not Success...</h1>}
        {passreq && <h1> {errorm.message} </h1>}
      </div>
    </div>
  );
};

export default Signup;
