import React from "react";
import {Link, Route, Routes} from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import Status from "./Status";
import { Account } from "./Account";

function Header() {
  let navigate = useNavigate(); 
  const routeChangetohome = () =>{
    let path = '/';
    navigate(path);
  }
  const routeChangetosignup = () =>{
    let path = '/signup';
    navigate(path);
  }
  const routeChangetologin = () =>{
    let path = '/login';
    navigate(path);
  }

  return (
    <Account>
          <button variant="contained" onClick={routeChangetohome}>
            Home
          </button>
          <button variant="contained" onClick={routeChangetosignup}>
            Signup
          </button>
          <button variant="contained" onClick={routeChangetologin}>
            Login
          </button>
          <Status />
    </Account>
  );
}

export default Header;
