import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from './Header'
import mrsReid from '../fotor-ai-20230527153116.jpg';
import uncleIchiro from '../062162ca-b33f-41d4-a365-5a1af9bb5d06.jpg';
import '../App.css';

function Clock() {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => setTime(new Date()), 1000);

    return () => clearInterval(timer);
  }, []);

  const hr_rotation = 30 * time.getHours() + time.getMinutes() / 2;
  const min_rotation = 6 * time.getMinutes();
  const sec_rotation = 6 * time.getSeconds();

  return (
    <div className="clockContainer">
      <div
        className="hour"
        style={{ transform: `rotate(${hr_rotation}deg)` }}
      ></div>
      <div
        className="minute"
        style={{ transform: `rotate(${min_rotation}deg)` }}
      ></div>
      <div
        className="second"
        style={{ transform: `rotate(${sec_rotation}deg)` }}
      ></div>
    </div>
  );
}

function Home() {
  const [time, setTime] = useState(new Date().toLocaleTimeString());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date().toLocaleTimeString());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="App">
      <Header />
      <main>
        <p className="time-display">{time}</p>
        <Clock />
        <a href="/mealadviser">
          <img src={mrsReid} alt="Clickable Image" width = "200" height = "200"/>
        </a>
        <p>Meal Prep Adviser</p>
        <a href="/uncleichiro">
          <img src={uncleIchiro} alt="Clickable Image" width = "200" height = "200"/>
        </a>
        <p>Uncle Ichiro</p>
      </main>
    </div>
  );
}

export default Home;

