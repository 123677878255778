import React, { useState, useContext, useEffect, useRef } from 'react';
import { AccountContext } from "./Account";
import { Account } from "./Account";
import '../App.css';

function MealAdviser() {
  const [status, setStatus] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const bottomRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [authorization, setAuthorization] = useState("");
  const { getSession, logout } = useContext(AccountContext);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (authorization) {
      fetch('https://8k65p3a1ue.execute-api.us-east-1.amazonaws.com/meal-prep-adviser/get-usermessage', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authorization
        },
      })
        .then(response => response.json())
        .then(responseData => {
          console.log(responseData);
          const itemsArray = responseData.Items;
          setMessages(itemsArray);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    }
  }, [authorization]);

  useEffect(() => {
    getSession().then((session) => {
      const token = session.idToken.jwtToken;
      setAuthorization(token);
      setStatus(true);
    });
  }, []);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'auto' });
  }, [messages]);

  const fetchOpenAiResponse = async (inputValue, authorization) => {
    const openAiResponse = await fetch(
      `https://ohd1napoid.execute-api.us-east-1.amazonaws.com/chat-completion-dev/gpt-response?message=${inputValue}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authorization
        },
      }
    );
    const aiData = await openAiResponse.json();
    console.log(aiData);
    return aiData;
  };

  const handleSendMessage = async () => {
    if (inputValue.trim() === '') {
      return;
    }

    setMessages(prevState => [
      ...prevState,
      { info: { msg: inputValue, id: 1684604873903, sender: 'user', user_id: 'user_id' } },
    ]);
  
    const sendObject = {
      id: Date.now(),
      msg: inputValue,
      sender: 'user',
    };
  
    try {
      setIsLoading(true);
      // Save the user message
      await fetch('https://8k65p3a1ue.execute-api.us-east-1.amazonaws.com/meal-prep-adviser/post-usermessage', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authorization,
        },
        body: JSON.stringify(sendObject),
      });
      
      // Fetch AI response
      //const openAiResponse = await fetch(
      //  `https://ohd1napoid.execute-api.us-east-1.amazonaws.com/chat-completion-dev/gpt-response?message=${inputValue}`,
      //  {
      //    method: 'GET',
      //    headers: {
      //      'Content-Type': 'application/json',
      //      Authorization: authorization
      //    },
      //  }
      //);
      //const aiData = await openAiResponse.json();

      //const aiData = fetchOpenAiResponse;

      //console.log(aiData)
      const aiData = await fetchOpenAiResponse(sendObject.msg, authorization);
  
      setMessages(prevState => [...prevState, { info: aiData }]);

      // Send AI response to the database
      const sendBotObject = {
        id: Date.now(),
        msg: aiData.msg, // Assuming there is a 'message' property in the AI response
        sender: 'bot',
      };

      await fetch('https://8k65p3a1ue.execute-api.us-east-1.amazonaws.com/meal-prep-adviser/post-botmessage', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authorization,
        },
        body: JSON.stringify(sendBotObject),
      });
    } catch (error) {
      console.error('Error sending message:', error);
    }
    setIsLoading(false);
    setInputValue('');
  };
  
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  };

  if (status) {
    return (
      <div className="grid-container">
        <div className="chat-history-left"></div>
        <div className="chat-history">
          {messages.map((message) => (
            <div key={message.info.id} style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '10px',
              width: '100 %',
            }}>
              <div style={{
                alignSelf: message.info.sender === 'user' ? 'flex-end' : 'flex-start',
                backgroundColor: message.info.sender === 'user' ? '#007bff' : '#e9ecef',
                color: messages.sender === 'user' ? '#fff' : '#000',
                padding: '10px',
                borderRadius: '10px',
                maxWidth: '70%',
                wordBreak: 'break-word',
              }}>
                <p>{message.info.msg}</p>
              </div>
              <div ref={bottomRef} />
            </div>
          ))}
          {isLoading && <div>Loading...</div>}
        </div >
        <div className="chat-history-right"></div>
        <div className="input-container">
          <input className="input-field" type="text" placeholder="Type your prompt"  value={inputValue} onChange={(e) => setInputValue(e.target.value)} onKeyPress={handleKeyPress}></input>
          <button className="send-button" onClick={handleSendMessage}>
            Send
          </button>
        </div>
      </div>
    );
  } else {
    return <div>Please Signin</div>
  }
  }

  export default () => {
    return (
      <div>
        <Account>
          <MealAdviser />
        </Account>
      </div>
    );
   };