import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import UserPool from "../UserPool";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isloggedin, setIsloggedin] = useState(null);
  const [notloggedin, setNotloggedin] = useState(null);
  const [errorm, setErrorm] = useState("")
  let navigate = useNavigate();
  const routeChangetohome = () =>{
    let path = '/';
    navigate(path);
  }

  const onSubmit = (event) => {
    event.preventDefault();

    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });

    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        console.log("onSuccess: ", data);
        setIsloggedin(true);
        setNotloggedin(false);
        routeChangetohome();
      },
      onFailure: (err) => {
        console.error("onFailure: ", err);
        setNotloggedin(true);
        setIsloggedin(false);
        setErrorm(err);
      },
      newPasswordRequired: (data) => {
        console.log("newPasswordRequired: ", data);
      },
    });
  };

  return (
    <div>
      <h1>Please login to your account</h1>
      <form onSubmit={onSubmit}>
        <label class="signup-label" htmlFor="email">Email</label>
        <input
          class="signup-input"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        ></input>
        <label class="signup-label" htmlFor="password">Password</label>
        <input
          class="signup-input"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        ></input>
        <button class="signup-button" type="submit">Login</button>
      </form>
      <div>
        {isloggedin && <h1>Success!</h1>}
        {notloggedin && <h1>Not Success...</h1>}
        {notloggedin && <h1> {errorm.message} </h1>}
      </div>
    </div>
  );
};

export default Login;
